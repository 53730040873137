import Vue from "vue";
import App from "./App.vue";
import { createRouter } from "@/router";
import { createStore } from "@/store/store";
import { sync } from "vuex-router-sync";
import VueModalPlugin from "@heavyind/vue-modal";


export const createApp = function createApp() {

  const router = createRouter();
  const store = createStore();

  sync(store, router);

  const app = new Vue({
    router,
    store,
    render: h => h(App)
  });

  Vue.use(VueModalPlugin, { store, mixin: true });

  return { app, router, store };
};
