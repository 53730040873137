

const pageMeta = {
  "/": {
    title: "Set Home title in ./src/settings.js",
    metaDescription: "Set me in ./src/settings.js"
  },
  "/about": {
    title: "Set About title in ./src/settings.js",
    metaDescription: "Set me in ./src/settings.js"
  }
};

module.exports = {
  pageMeta
};
