<template>
  <div id="app">
    <router-view></router-view>
    <modal :isOpen="this.$modal.isOpen"></modal>
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "@/component/modal/Index.vue";

export default {
  name: "app",
  components: {
    Modal
  },
  watch: {
    $route () {
      if (this.$route.meta && this.$route.meta.title) {
        document.title = this.$route.meta.title;
      }
    }
  }
};
</script>

<style lang="sass">
@import "~SASS/main";
</style>
