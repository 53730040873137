<template>
  <transition name="modal">
    <div
      v-if="isOpen"
      class="modal">
    </div>
  </transition>
</template>

<script>


export default {
  name: "Modal",
  props: {
    isOpen: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    handleEscape (e) {
      if (e.keyCode === 27) {
        this.$modal.close();
      }
    }
  },
  mounted () {
    addEventListener("keydown", this.handleEscape);
  },
  beforeDestroy () {
    removeEventListener("keydown", this.handleEscape);
  }
};
</script>

<style lang="sass">

.modal
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: black
  z-index: 10

.modal-enter-active
  opacity: 1
  transition: opacity 250ms linear

.modal-enter
  opacity: 0

.modal-leave-active
  opacity: 0
  transition: opacity 250ms linear

.modal-leave
  opacity: 1
</style>
